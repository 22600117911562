@use 'styles/config' as *;

.message {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;

  &__inner {
    @include render-copy;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 3rem;

    width: 100%;
    height: 100%;

    max-width: 80rem;
    max-height: 30rem;

    text-align: center;
    text-wrap: balance;

    background: #fff;
  }
}
